// import React, { Component } from 'react';
// import ImgOne from '../images/img-1.jpg';
// import ImgTwo from '../images/img-2.jpg';
// import ImgThree from '../images/img-3.jpg';

// class LatestNews extends Component {
//   render() {
//     return(
// <div class="section sec-news">
// 	<div class="container">
// 		<div class="row mb-5">
// 			<div class="col-lg-7">
// 				<h2 class="heading text-uppercase" style={{color:'#BF953F'}}>Latesdsfasdfdt News</h2>
// 			</div>
// 		</div>

// 		<div class="row">
// 			<div class="col-lg-4">
// 				<div class="card post-entry">
// 					<a href="single.html"><img src={ImgOne} class="card-img-top" alt="Image" /></a>
// 					<div class="card-body">
// 						<div><span class="text-uppercase font-weight-bold date">Jan 20, 2021</span></div>
// 						<h5 class="card-title text-decoration-none"><a href="single.html" class="text-decoration-none fw-bold" style={{color:'#BF953F'}}>Behind the word mountains</a></h5>
// 						<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. </p>
// 						<p class="mt-5 mb-0"><a href="#" style={{color:'#BF953F'}}>Read more</a></p>
// 					</div>
// 				</div>
// 			</div>

// 		</div>
// 	</div>
// </div>
//     )
//   }
// }

// 80d4a89182094fd2ab8cfd631f636637

// export default LatestNews;

import React, { useState, useEffect } from "react";
import axios from "axios";

const SouthAfricanNews = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace 'YOUR_API_KEY' with your actual API key
    const fetchNews = async () => {
      try {
        const response = await axios.get("https://newsdata.io/api/1/latest?apikey=pub_657332ecc9e481137348f1d08b9d9e98b2ec0", {
          params: {
            category: "politics",
						cuntry: "za",
            // apiKey: process.env.REACT_APP_API_KEY,
            size: 3, // Limit to 3 articles
            language: "en",
          },
        });
        setArticles(response.data.results);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news:", error);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // You can format this as you wish, e.g., "January 17, 2025, 1:00 PM"
    return date.toLocaleString("en-US", {
      weekday: "short", // Full weekday name (e.g., Monday)
      year: "numeric",
      month: "short", // Full month name (e.g., January)
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 12-hour format (AM/PM)
    });
  };

  return (
    <div class="section sec-news">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-7">
            <h2
              class="heading text-uppercase bold"
              style={{ color: "#BF953F", fontWeight: "700" }}
            >
              Latest News
            </h2>
          </div>
        </div>
        <div class="row">
				<div class="d-flex">
          {articles.map((article, index) => (
            <div key={index} class="w-50">
                <div class="card post-entry h-full mx-1">
                  <img
                    src={article.image_url}
                    alt={article.title}
                    style={styles.cardImage}
                  />
                  <div class="card-body">
                    <p class="text-uppercase font-weight-bold date">
                      {formatDate(article.pubDate)}
                    </p>
                    <h5
                      class="text-decoration-none fw-bold"
                      style={{ color: "#BF953F" }}
                    >
                      {article.title}
                    </h5>
                    <p style={styles.cardDescription}>{article.description || article.content }</p>
                    <a
                      href={article.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      class="mt-5 mb-0"
                      style={{ color: "#BF953F" }}
                    >
                      Read more
                    </a>
                  </div>
                </div>
            </div>
          ))}
        </div>
				</div>
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "20px",
  },
  card: {
    width: "30%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    transition: "transform 0.2s ease-in-out",
  },
  cardImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
  cardContent: {
    padding: "15px",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0 0 10px 0",
  },
  cardDescription: {
    fontSize: "14px",
    marginBottom: "10px",
    color: "#555",
  },
  cardLink: {
    fontSize: "14px",
    color: "#007bff",
    textDecoration: "none",
  },
};

export default SouthAfricanNews;
