import React, { Component } from "react";
import Nadezna from '../images/Nadezna.jpg';
import Alaythea from "../images/Alaythea.jpg";
import Chad from "../images/Chad.jpg";

class Team extends Component {
  render() {
    return (
      <div class="section">
        <div class="container">
          <div class="row mb-5">
            <div class="mx-auto text-center" data-aos="fade-up">
              <h2 class="heading text-uppercase" style={{ color: "#BF953F" }}>
                The brains behind <br /> Asher Financial Solutions
              </h2>
              <p>
                Lorem ipsum dolor sit amet. Ut impedit maiores qui cumque
                galisum qui quod reprehenderit qui repudiandae enim et veniam
                laborum sit maxime quisquam est sunt illo
              </p>
            </div>
          </div>

          <div class="row">
            <div
              class="col-lg-4 mb-4 text-center"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <img
                src={Nadezna}
                alt="Image"
                class="w-50 rounded-circle mb-3"
                style={{height:"70%"}}
              />
              <h5 class="text-black">Nadezna Holland</h5>
              <p>
                Founder & Managing Director
              </p>
            </div>
            <div
              class="col-lg-4 mb-4 text-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src={Alaythea}
                alt="Image"
                class="img-fluid w-50 rounded-circle mb-3"
                style={{height:"70%"}}
              />
              <h5 class="text-black">Alaythea Le Fleur</h5>
              <p>
                Financial Director
              </p>
            </div>
            <div
              class="col-lg-4 mb-4 text-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                src={Chad}
                alt="Image"
                class="img-fluid w-50 rounded-circle mb-3"
                style={{height:"70%"}}
              />
              <h5 class="text-black">Chad Malgas</h5>
              <p>
                Operations Director
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
